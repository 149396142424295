import { createStore } from 'vuex'
import {login, logout} from "@/api/auth";
import {useToast} from "vue-toast-notification";
import {getUsers, updateUser} from "@/api/user";
import {updateAdmin} from "@/api/admin";

const localAdmin = localStorage.getItem('admin');
export default createStore({
  state: {
    admin: localAdmin ? JSON.parse(localAdmin) : {
      name: '',
      email: '',
      token: '',
      expires_at: ''
    },
    users: []
  },
  getters: {
    isAdminLoggedIn: (state) => {
      return state.admin.token !== '';
    },
    authToken: (state) => {
      return state.admin.token;
    }
  },
  mutations: {
    setAdmin(state, admin) {
      state.admin.name = admin.user.name;
      state.admin.email = admin.user.email;
      state.admin.token = admin.token;
      state.admin.expires_at = admin.expires_at;
      localStorage.setItem('admin', JSON.stringify(state.admin));
    },
    removeAdmin(state) {
      state.admin = {
        name: '',
        email: '',
        token: '',
        expires_at: ''
      }
      localStorage.removeItem('admin')
    },
    setUsers(state, users) {
      state.users = users;
    }
  },
  actions: {
    login: async function ({commit, dispatch}, {email, password, captchaId, captcha}) {
      try {
        const admin = await login(email, password, captchaId, captcha);
        if (admin) {
          commit('setAdmin', admin.data);
          useToast().success("登录成功");
          return true;
        }
        useToast().error("登录失败");
        return false;
      } catch (e: any) {
        useToast().error("登录失败");
        return false;
      }
    },
    updateUser: async function({commit, dispatch}, params) {
      try {
        const res = await updateUser(params.id, params.data)
        useToast().success("更新用户成功")
      } catch (e) {
        useToast().error("更新用户失败")
      }
    },
    logout: async function ({commit}) {
      try {
        await logout();
        commit('removeAdmin');
        useToast().success("登出成功");
      } catch (e) {
        useToast().error("登出失败");
      }
    },
    getUsers: async function ({commit}, page = 1) {
      try {
        const users = await getUsers(page);
        console.log(users);
        commit('setUsers', users.data.data);
      } catch (e) {
        console.log(e);
        useToast().error("获取用户列表失败");
      }
    },
  },
  modules: {
  }
})
