import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import {library} from '@fortawesome/fontawesome-svg-core'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import {fas} from '@fortawesome/free-solid-svg-icons'
import {fab} from '@fortawesome/free-brands-svg-icons'
import {far} from '@fortawesome/free-regular-svg-icons'
import moment from "moment";

library.add(fas)
library.add(fab)
library.add(far)

moment.locale('zh-cn', {
    relativeTime: {
        future: '%s内',
        past: '%s前',
        s: '几秒',
        ss: '%d秒',
        m: '1分钟',
        mm: '%d分钟',
        h: '1小时',
        hh: '%d小时',
        d: '1天',
        dd: '%d天',
        M: '1个月',
        MM: '%d个月',
        y: '1年',
        yy: '%d年'
    }
})

import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-bootstrap.css'
import './assets/css/tailwind.css'
createApp(App)
    .component('fai', FontAwesomeIcon)
    .use(store)
    .use(router)
    .use(VueToast, {position: 'top', duration: 1000})
    .mount('#app')
