<template>
  <div class="home flex flex-col absolute w-full h-full left-0 top-0">
    <div class="h-[40px] flex justify-center items-center">
      <div class="ml-[200px] text-[0.8em] text-gray-500">
        {{admin.name}}({{admin.email}})
      </div>
      <div @click="onLogout" class="cursor-pointer ml-auto mr-[20px] text-[0.8em] text-gray-500">
        退出登录
      </div>
    </div>
    <div class="flex-1 flex relative">
      <div class="w-[200px] h-full">
        <SideMenu/>
      </div>
      <div class="absolute left-[200px] top-0 w-[calc(100%-200px)] h-full overflow-y-auto scrollbar-thin scrollbar-track-white scrollbar-thumb-gray-500 scrollbar-track-slate-gray-300 scrollbar-thumb-rounded-full pt-4">
        <router-view/>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapState} from "vuex";
import SideMenu from "@/components/SideMenu.vue";

export default {
  name: 'HomeView',
  components: {SideMenu},
  data() {
    return {}
  },
  computed: {
    ...mapState({
      admin: state => state.admin
    })
  },
  methods: {
    ...mapActions({
      logout: 'logout'
    }),
    detectLogin() {
      if (this.admin.token === '') {
        this.$router.push({name: 'login'})
      }
    },
    async onLogout() {
      await this.logout()
      this.$router.push({name: 'login'})
    },
  },
  mounted() {
    this.detectLogin()
  }
}
</script>
