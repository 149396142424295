import {ResponseType} from "@/libs/http";
import api  from '@/libs/http';

export const login = async (email: string, password: string, captchaId:string, captcha:string) => {
    return await api.post("auth/login", {
        json: {
            email,
            password,
            captcha_id: captchaId,
            captcha,
        }
    }).json<ResponseType>();
}

export const logout = async () => {
    return await api.post("auth/logout").json<ResponseType>();
}