import ky from "ky";
import {useToast} from "vue-toast-notification";

const baseURL = process.env.NODE_ENV === "production" ? "https://internal.tohsun.com/api" : "http://localhost:8080/api";

export interface ResponseType {
    data: any;
}
const api = ky.extend({
    prefixUrl: baseURL,
    hooks: {
        beforeError: [
            error => {
                const {response} = error;
                if (response && response.body) {
                    if(response.status === 401) {
                        useToast().error('没有权限');
                    }

                    if(response.status == 403) {
                        useToast().error('没有权限');
                    }

                    if(response.status == 400) {
                        useToast().error('参数错误');
                    }

                    if(response.status == 500) {
                        useToast().error('服务器错误');
                    }

                    if(response.status == 404) {
                        useToast().error('资源不存在');
                    }
                }
                return error;
            }
        ],
        beforeRequest: [
            async (request) => {
                const admin = localStorage.getItem('admin');
                if (admin) {
                    const adminObj = JSON.parse(admin);
                    request.headers.set('Authorization', `Bearer ${adminObj.token}`);
                }
            }
        ],
        afterResponse: [
            async (request, options, response) => {
                const address = window.location.href.split('/')
                const path = address.pop()
                if(path !== 'login' && response.status === 401) {
                    localStorage.removeItem('admin');
                    window.location.href = '/login';
                }
                return response;
            }
        ]
    },
})

export default api