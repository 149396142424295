<script>

export default {
  name: 'SideMenu',
  data() {
    return {
      menus: [
        {
          path: '/',
          id: 0,
          icon: 'home',
          title: '数据统计',
        },
        {
          path: '/users',
          id: 1,
          icon: 'user',
          title: '用户管理',
        },
        {
          path: '/orders',
          id: 2,
          icon: 'shopping-cart',
          title: '订单管理',
        },
        {
          path: '/products',
          id: 3,
          icon: 'box',
          title: '商品管理',
        },
        {
          path: '/purchase',
          id: 4,
          icon:'shopping-cart',
          title: '购物测试',
        },
        {
          path: '/rates',
          id: 5,
          icon: 'chart-simple',
          title: '额度管理'
        }
      ]
    }
  },
  methods: {
    onMenuSelected(id) {
      this.$router.push({path: this.menus[id].path})
    },
  },
}
</script>

<template>
  <div class="m-2 rounded">
    <div @click="onMenuSelected(menu.id)" v-for="menu in menus" :key="menu.id" class="cursor-pointer select-none h-[40px] text-gray-400 flex justify-center items-center hover:bg-gray-200 rounded transition-all" :class="{'text-green-500  hover:!bg-gray-200': $route.path === menu.path}">
      <fai :icon="menu.icon" class="text-[0.8em]"/>
      <span class="ml-2 text-[0.8em]">{{ menu.title }}</span>
    </div>
  </div>
</template>

<style scoped>

</style>