import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    children: [
      {
        path: '/',
        name: 'dashboard',
        component: () => import('../views/DashboardView.vue')
      },
      {
        path: '/users',
        name: 'users',
        component: () => import('../views/UsersView.vue')
      },
      {
        path:'/users/:id',
        name:'user',
        component: () => import('../views/UserDetail.vue')
      },
      {
        path: '/products',
        name: 'products',
        component: () => import('../views/ProductsView.vue')
      },
      {
        path: '/orders',
        name: 'orders',
        component: () => import('../views/OrdersView.vue')
      },
      {
        path: '/purchase',
        name:'purchase',
        component: () => import('../views/PurchaseView.vue')
      },
      {
        path: '/user-edit/:id',
        name: 'user-edit',
        component: () => import('../views/UserEditView.vue')
      },
      {
        path: '/confirm-order/:orderId',
        name: 'confirm-order',
        component: () => import('../views/ConfirmOrderView.vue')
      },
      {
        path: '/payment/:orderId',
        name: 'payment',
        component: () => import('../views/PaymentView.vue')
      },
      {
        path:'/order-detail/:orderId',
        name:'order-detail',
        component: () => import('../views/OrderDetailView.vue')
      },
      {
        path: '/product-detail/:id',
        name: 'product-detail',
        component: () => import('../views/ProductDetailView.vue')
      },
      {
        path: '/rates',
        name: 'rates',
        component: () => import('../views/RateQuotas.vue')
      }
    ]
  },
  {
    path:'/login',
    name:'login',
    component: () => import('../views/LoginView.vue')
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
const admin = localStorage.getItem('admin');
  if (to.name !== 'login' && !admin) next({ name: 'login' })
  else next()
})
export default router
