import api from '@/libs/http';
import {ResponseType} from "@/libs/http";

export async function getUsers(page:number = 1)  {
    return await api.get(`app/users?page=${page}`).json<ResponseType>();
}

export async function getUser(id:number) {
    return await api.get('app/users/' + id).json<ResponseType>();
}

export const updateUser = (id:number, data: any): Promise<ResponseType> => {
    return api.put('app/users/' + id, {
        json: data
    }).json<ResponseType>();
}

export const syncWechatProfile = (id:number) => {
    return api.post("app/user-sync/" + id).json<ResponseType>();
}
